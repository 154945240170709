import {createRouter, createWebHistory} from 'vue-router'
import store from './store';
import Permission from "./config/permission.js";
import {Auth} from "./config/auth.js";
import axios from "axios";

const routes = [
    {
        path: '/tools/home',
        name: 'home',
        component: () => import('./views/Home.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tools/user',
        name: 'user',
        component: () => import('./views/User.vue'),
        meta: {
            permission: Permission.P_MASK_USER_SEARCH
        },
    },
    {
        path: '/tools/master-update',
        name: 'master-update',
        component: () => import('./views/MasterUpdate.vue'),
        meta: {
            permission: Permission.P_MASK_MASTER_UPDATE
        },
    },
    {
        path: '/tools/master-sync',
        name: 'master-sync',
        component: () => import('./views/MasterSync.vue'),
        meta: {
            permission: Permission.P_MASK_MASTER_SYNC
        },
    },
    {
        path: '/tools/master-view',
        name: 'master-view',
        component: () => import('./views/MasterView.vue'),
        meta: {
            permission: Permission.P_MASK_MASTER_VIEW
        },
    },
    {
        path: '/tools/version-control',
        name: 'version-control',
        component: () => import('./views/VersionControl.vue'),
        meta: {
            permission: Permission.P_MASK_VERSION_CONTROL
        },
    },
    {
        path: '/tools/promotion-code',
        name: 'promotion-code',
        component: () => import('./views/PromotionCode.vue'),
        meta: {
            permission: Permission.P_MASK_PROMOTION_CODE
        },
    },
    {
        path: '/tools/account-manage',
        name: 'account-manage',
        component: () => import('./views/AccountManage.vue'),
        meta: {
            permission: Permission.P_MASK_ACCOUNT_MANAGE
        },
    },
    {
        path: '/tools/schedule',
        name: 'schedule',
        component: () => import('./views/Schedule.vue'),
        meta: {
            permission: Permission.P_MASK_SCHEDULE
        },
    },
    {
        path: '/tools/grant-item',
        name: 'grant-item',
        component: () => import('./views/GrantItem.vue'),
        meta: {
            permission: Permission.P_MASK_GRANT
        },
    },
    {
        path: '/tools/confiscate-item',
        name: 'confiscate-item',
        component: () => import('./views/ConfiscateItem.vue'),
        meta: {
            permission: Permission.P_MASK_CONFISCATE
        },
    },
    {
        path: '/tools/action-log',
        name: 'action-log',
        component: () => import('./views/ActionLog.vue'),
        meta: {
            permission: Permission.P_MASK_ACTION_LOG
        },
    },
    {
        path: '/tools/account-settings',
        name: 'account-settings',
        component: () => import('./views/AccountSettings.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tools/tool-log',
        name: 'tool-log',
        component: () => import('./views/ToolLog.vue'),
        meta: {
            permission: Permission.P_MASK_TOOL_LOG
        }
    },
    {
        path: '/tools/receipt',
        name: 'receipt',
        component: () => import('./views/Receipt.vue'),
        meta: {
            permission: Permission.P_MASK_RECEIPT
        },
        props: true
    },
    {
        path: '/tools/redis-manage',
        name: 'redis-manage',
        component: () => import('./views/RedisManage.vue'),
        meta: {
            permission: Permission.P_MASK_REDIS_MANAGE
        }
    },
    {
        path: '/tools/matching-view',
        name: 'matching-view',
        component: () => import('./views/MatchingView.vue'),
        meta: {
            permission: Permission.P_MASK_REDIS_MANAGE
        }
    },
    {
        path: '/tools/memcache-manage',
        name: 'memcache-manage',
        component: () => import('./views/MemcacheManage.vue'),
        meta: {
            permission: Permission.P_MASK_MEMCACHE_MANAGE
        }
    },
    {
        path: '/tools/gdpr',
        name: 'gdpr',
        component: () => import('./views/Gdpr.vue'),
        meta: {
            permission: Permission.P_MASK_GDPR
        }
    },
    {
        path: '/tools/user-delete',
        name: 'user-delete',
        component: () => import('./views/UserDelete.vue'),
        meta: {
            permission: Permission.P_MASK_GDPR
        }
    },
    {
        path: '/tools/debug',
        name: 'debug',
        component: () => import('./views/Debug.vue'),
        meta: {
            permission: Permission.P_MASK_DEBUG
        }
    },
    {
        path: '/tools/debug-log',
        name: 'debug-log',
        component: () => import('./views/DebugLog.vue'),
        meta: {
            permission: Permission.P_MASK_DEBUG_LOG
        }
    },
    {
        path: '/tools/ban',
        name: 'ban',
        component: () => import('./views/Ban.vue'),
        meta: {
            permission: Permission.P_MASK_BAN
        }
    },
    {
        path: '/tools/login',
        name: 'login',
        component: () => import('./views/Login.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// ナビゲーションガード
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.permission)) {
        if (!store.getters['auth/isLogin']) {
            // 未ログインならログインページへ
            next({
                name: 'login'
            });
        } else {
            // パーミッションのないページへアクセスした場合はトップページへ戻す
            let permission = 0
            await axios.get('/api/me').then(response => {
                permission = response.data["permission"]
            });

            let toPagePermission = to.meta.permission
            const isForbidden = (toPagePermission != null && !(((permission & toPagePermission) === toPagePermission)));
            if (isForbidden) {
                next({
                    name: 'home'
                });
            } else {
                next();
            }
        }
    } else {
        next();
    }
});

router.onError((error) => {
    const chunkLoadFailedPattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(chunkLoadFailedPattern);
    if (isChunkLoadFailed) {
        window.location.reload();
    }
});

export default router;